import React from 'react';
import PropTypes from 'prop-types';
import IconsGridWrapper, { IconsGridRowWrapper } from './iconsGrid.style';

import Image from 'common/components/Image';

export const ItemWrapper = ({ icon, alt, title, subtitle, description, borderOpacity, link }) => {
  return (
    <div className="item-wrapper">
      <a href={link}>
        <div className="image-wrapper">
          <Image src={icon} alt={alt} loading="lazy" />
        </div>
        <div className="title">{title}</div>
        <div className="subtitle">{subtitle}</div>
        <p className="description">{description}</p>
        <div className="border-wrapper" style={{ opacity: borderOpacity }} />
      </a>
    </div>
  );
};

const IconsGrid = ({ items }) => {
  return (
    <IconsGridWrapper>
      <div className="grid-col">
        <ItemWrapper {...items[0]} />
        <ItemWrapper {...items[2]} />
      </div>
      <div className="grid-col">
        <ItemWrapper {...items[1]} />
        <ItemWrapper {...items[3]} />
      </div>
    </IconsGridWrapper>
  );
};

export const IconsGridRow = ({ items }) => {
  return (
    <IconsGridRowWrapper>
      <ItemWrapper {...items[0]} />
      <ItemWrapper {...items[1]} />
      <ItemWrapper {...items[2]} />
    </IconsGridRowWrapper>
  );
};

IconsGrid.propTypes = {
  items: PropTypes.array,
};

IconsGrid.defaultProps = {
  items: [],
};

export default IconsGrid;
