import styled from 'styled-components';
import Dots from 'common/assets/image/syncStage/dots.png';

const PartnerSectionWrapper = styled.section`
  padding: 150px 0;
  background-image: url(${Dots});
  background-repeat: no-repeat;
  background-position: right 100px;

  @media (max-width: 990px) {
    padding: 100px 0;
  }
  @media (max-width: 767px) {
    padding: 80px 0;
    background-image: none;
  }
  @media (max-width: 575px) {
    padding: 60px 0;
    background-image: none;
  }
`;

export default PartnerSectionWrapper;

export const GridWrapper = styled.div`
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  justify-content: center;
`;

export const styles = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentWrapper: {
    width: '100%',
    mb: '40px',
  },
  title: {
    fontSize: ['26px', '34px', '42px', '44px', '48px'],
    fontWeight: 'bold',
    color: '#fff',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px', '25px', '25px', '25px'],
    lineHeight: '1',
    textAlign: 'center',
    fontStyle: 'normal',
  },
};
