import styled from 'styled-components';
import Bg from 'common/assets/image/syncStage/benefits-bg.png';
import BgOptimized from 'common/assets/image/syncStage/bg-optimized.png';

const IconsGridWrapper = styled.section`
  background-image: url(${Bg});
  background-size: cover;
  display: flex;
  font-weight: bold;
  font-size: 25px;
  line-height: 125%;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  .grid-col {
    &:nth-child(1) {
      margin-left: 20px;
      margin-top: -40px;
      .item-wrapper {
        &:nth-child(1) {
          margin-left: -10px;
          margin-right: 10px;
        }
      }
    }
    &:nth-child(2) {
      margin-left: 20px;
      margin-right: 20px;
      margin-top: 60px;
    }
  }
  .item-wrapper {
    background: #414c55;
    padding: 30px;
    margin-bottom: 20px;
    position: relative;
  }
  .border-wrapper {
    border: 3px solid;
    border-image-slice: 1;
    border-image-source: radial-gradient(
      89.85% 105.84% at 44.89% 3.77%,
      #00fff0 0%,
      #00c2ff 17.71%,
      #004cdf 40.63%,
      #321497 58.85%,
      #9800cd 81.25%,
      #cd00a0 100%
    );
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
  }
  .image-wrapper {
    display: flex;
    justify-content: center;
    padding: 20px;
  }
  .title {
    color: #fff;
    display: block;
  }
  .subtitle {
    color: #36dbfd;
  }
`;

export default IconsGridWrapper;

export const IconsGridRowWrapper = styled.section`
  background-image: url(${BgOptimized});
  background-size: cover;
  display: flex;
  font-weight: bold;
  font-size: 25px;
  line-height: 125%;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  padding: 100px 20px;

  @media (max-width: 575px) {
    text-align: center;
    padding: 0;
    background-image: none;
    flex-direction: column;
  }

  .item-wrapper {
    background: #414c55;
    padding: 30px;
    margin-bottom: 20px;
    position: relative;
    width: 100%;
    margin: 10px 20px;
  }
  .border-wrapper {
    border: 3px solid;
    border-image-slice: 1;
    border-image-source: radial-gradient(
      89.85% 105.84% at 44.89% 3.77%,
      #00fff0 0%,
      #00c2ff 17.71%,
      #004cdf 40.63%,
      #321497 58.85%,
      #9800cd 81.25%,
      #cd00a0 100%
    );
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
  }
  .image-wrapper {
    display: flex;
    justify-content: center;
    padding: 20px;
    min-height: 130px;
  }
  .title {
    max-width: 220px;
    color: #fff;
    display: block;
    min-height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
  .subtitle {
    color: #36dbfd;
    margin: auto;
  }
  .description {
    color: white;
    text-transform: none;
    font-weight: 400;
    font-size: 18px;
    margin: auto;
    max-width: 200px;
  }
`;
