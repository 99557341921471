import React from 'react';
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';

import Vodafone from 'common/assets/image/syncStage/card-vodafone.png';
import Verizon from 'common/assets/image/syncStage/card-verizon.png';
import Rogers from 'common/assets/image/syncStage/card-rogers.png';
import Singtel from 'common/assets/image/syncStage/card-singtel.png';
import SKGaming from 'common/assets/image/syncStage/card-gaming.png';
import Hubraum from 'common/assets/image/syncStage/card-hubraum.png';
import Wayra from 'common/assets/image/syncStage/card-wayra.png';
import Aws from 'common/assets/image/syncStage/card-aws.png';
import SKTelecom from 'common/assets/image/syncStage/card-sk-telecom.png';
import FilmUndMedien from 'common/assets/image/syncStage/card-film-und-medien.png';

import PartnerSectionWrapper, { GridWrapper, styles } from './partner.style';
import Image from 'common/components/Image';
import Fade from 'react-reveal/Fade';

const PartnerSection = () => {
  const { row, title, contentWrapper } = styles;
  return (
    <PartnerSectionWrapper>
      <Container>
        <Box {...row}>
          <Box {...contentWrapper}>
            <Heading {...title} content="Our collaborators" />
          </Box>
        </Box>
        <Box {...row}>
          <Fade bottom>
            <GridWrapper>
              <Image src={Verizon} alt={'Verizon'} loading="lazy" />
              <Image src={SKTelecom} alt={'SK Telecom'} loading="lazy" />
              <Image src={Singtel} alt={'Singtel'} loading="lazy" />
              <Image src={Aws} alt={'aws'} loading="lazy" />
              <Image src={Rogers} alt={'Rogers'} loading="lazy" />
              <Image src={Vodafone} alt={'Vodafone'} loading="lazy" />
              <Image src={SKGaming} alt={'SK Gaming'} loading="lazy" />
              <Image src={FilmUndMedien} alt={'Film und Medien Stiftung NRW'} loading="lazy" />
              <Image src={Hubraum} alt={'Hub:raum'} loading="lazy" />
              <Image src={Wayra} alt={'Wayra Inovation'} loading="lazy" />
            </GridWrapper>
          </Fade>
        </Box>
      </Container>
    </PartnerSectionWrapper>
  );
};

export default PartnerSection;
